import { CommonModule } from "@angular/common";
import { Component, type OnInit } from "@angular/core";
import { firstValueFrom, map, tap } from "rxjs";
import OrderStatusUtils, {
  OrderStatus,
} from "src/app/shared/models/order-status.enum";
import StatusUtils from "src/app/shared/models/status.enum";
import { ConvertUtcToLocalPipe } from "src/app/shared/pipes/convert-utc-to-local.pipe";
import { HttpService } from "src/app/shared/services/http.service";
import { confirm } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { OrderType } from "src/app/shared/models/OrderType";

@Component({
  selector: "app-order-list",
  templateUrl: "./order-list.component.html",
  styleUrls: ["./order-list.component.scss"],
})
export class OrderListComponent implements OnInit {
  tabs = ["Online Siparişler", "Pos Siparişler", "Tamamlanmış Siparişler"];
  selectedTabIndex = 0;

  accordionPanels = ["Yeni Siparişler", "Devam Eden Siparişler"];
  accordionOnlinePanels = ["Pos Siparişleri"];
  accordionComplatePanels = ["Tamamlanmış Siparişler"];


  completedOrders: any[] = [];
  onlineOrders: any[] = [];
  activeOnlineOrders: any[] = [];
  activePosOrders: any[] = [];




  orderCounts = [];

  isLoading = false;

  cancelReasons = [];
  selectedOrderForCancel = { id: null, cancelReasonId: null, note: null };

  cancelPopupVisible = false;

  constructor(
    private httpService: HttpService,
    private convertUtcToLocale: ConvertUtcToLocalPipe
  ) { }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    this.isLoading = true;
    Promise.all([
      this.getNewOrders(),
      this.getActiveOrders(),
       this.getCompletedOrders(),

    
        


    ]).then((x) => {
      this.isLoading = false;
      this.orderCounts = [
        this.onlineOrders.length + this.activeOnlineOrders.length,
        this.activePosOrders.length,    
        this.completedOrders.length];
    });
  }

  getNewOrders(): Promise<any> {
    return firstValueFrom(
      this.httpService
        .get("Order", {
          params: OrderStatusUtils.GetOrderAndStatusParams(
            OrderStatusUtils.NewOrderStatuses
          ),
        })
        .pipe(
          map((x) => {
            //#region  Online Sipariş Filtreleme
            const filteredNewOnlineOrders = x.filter((element) => element.orderType == OrderType.Delivery || element.orderType == OrderType.Handover);
            filteredNewOnlineOrders.forEach((element) => {
              element.orderDatetime = this.convertUtcToLocale.transform(
                element.orderDatetime
              );
              element.paymentMethodName = element.paymentMethodName ?? 'Online Ödeme';
            });
            this.onlineOrders = filteredNewOnlineOrders;
            //#endregion
          })
        ),

    );
  }

  getActiveOrders(): Promise<any> {
    return firstValueFrom(
      this.httpService
        .get("Order", {
          params: OrderStatusUtils.GetOrderAndStatusParams(
            OrderStatusUtils.ActiveOrderStatuses
          ),
        })
        .pipe(
          map((x) => {


            //#region Aktif Online Sipariş Filtrelemesi

            const filteredActiveOnlineOrders = x.filter((element) =>
               element.orderType == OrderType.Delivery ||
             element.orderType == OrderType.Handover);
            filteredActiveOnlineOrders.forEach((element) => {
              element.orderDatetime = this.convertUtcToLocale.transform(
                element.orderDatetime
              );
              element.paymentMethodName = element.paymentMethodName ?? 'Online Ödeme';
            });
            this.activeOnlineOrders = filteredActiveOnlineOrders;

            //#endregion

            //#region  Pos Sipariş Filtrelemesi
            const filtredActivePosOrders = x.filter(
              (element) =>
                element.orderType === OrderType.Pos ||
                element.orderType === OrderType.TakeAway
            );
            filtredActivePosOrders.forEach((element) => {
              element.orderDatetime = this.convertUtcToLocale.transform(
                element.orderDatetime
              );
              element.paymentMethodName = element.paymentMethodName ?? 'Online Ödeme';
            });
            this.activePosOrders = filtredActivePosOrders;

            //#endregion


          }),
          tap((x) => {
          })
        )
    );
  }

  getCompletedOrders(): Promise<any> {
    return firstValueFrom(
      this.httpService
        .get("Order", {
          params: OrderStatusUtils.GetOrderAndStatusParams(
            OrderStatusUtils.CompletedOrderStatuses
          ),
        })
        .pipe(
          map((x) => {
            x.forEach((element) => {
              element.orderDatetime = this.convertUtcToLocale.transform(
                element.orderDatetime
              );
              element.paymentMethodName = element.paymentMethodName ?? 'Online Ödeme';
            });
            return x;
          }),
          tap((x) => {
            this.completedOrders = x;
          })
        )
    );
  }



  async updateOrderStatus(data) {
    let result = await confirm(
      `Siparişin durumunu <b>${OrderStatusUtils.GetNextStatusText(
        data.status
      )}</b> olarak güncellemek istediğinize emin misiniz??`,
      "Durum Güncelleme Onayı"
    );

    if (!result) {
      return;
    }

    this.httpService
      .put("Order", {
        orderId: data.id,
        orderStatus: OrderStatusUtils.NextOrderStatusDict[data.status],
      })
      .subscribe(
        (res) => {
          this.reload();
        },
        (e) => {
          notify(
            {
              message: 'İşlem başarısız oldu.',
              width: 450,
            },
            'error',
            2000
          );
        }
      );
  }


  async openCancelReasonModal(order) {
    this.selectedOrderForCancel = { id: order.id, cancelReasonId: null, note: null };
    this.cancelReasons = [];
    if (order.provider == 0) {
      const cancelReasons: any[] = await firstValueFrom(this.httpService.get('Order/GetirCancelOptionByOrderId?orderId=' + order.id));
      if (cancelReasons.length == 0) {
        notify(
          {
            message: 'Bu sipariş iptal edilemez.',
            width: 450,
          },
          'error',
          2000
        );
      }
      this.cancelReasons = cancelReasons;
      this.selectedOrderForCancel.cancelReasonId = cancelReasons[0].id
    }

    this.cancelPopupVisible = true;
  }


  cancelOrder = async function () {
    let result = await confirm(
      `Siparişi <b>iptal</b> etmek istediğinize emin misiniz??`,
      "Sipariş İptal Onayı"
    );

    if (!result) {
      return;
    }

    this.httpService
      .put("Order", {
        orderId: this.selectedOrderForCancel.id,
        orderStatus: OrderStatus.OrderCancelledByRestaurant,
        cancelReasonId: this.selectedOrderForCancel.cancelReasonId,
        cancelReasonText: this.selectedOrderForCancel.note
      })
      .subscribe(
        (res) => {
          this.reload();
          this.onCloseClicked()
        },
        (e) => {
          notify(
            {
              message: 'İşlem başarısız oldu.',
              width: 450,
            },
            'error',
            2000
          );
        }
      );
  }.bind(this);


  onCloseClicked = function () {
    this.cancelPopupVisible = false;
    this.selectedOrderForCancel = null;
  }.bind(this);
}
