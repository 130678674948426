<app-page-header
  *ngIf="getOrderIdFromParams"
  [titleText]="'Sipariş Detay'"
  [hasAddButton]="false"
></app-page-header>

<div *ngIf="order" class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="dx-card dx-wide-card py-2 px-3">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <p class="mb-1 h5">
              <strong>Sipariş#:</strong> {{ order?.orderSerialNo }}
            </p>
            <p>
              <span>Sağlayıcı:</span>
              {{ order?.provider == 0 ? "Getir" : "POS" }}
            </p>
          </div>

          <dx-button
            icon="print"
            type="default"
            text="Yazdır"
            (onClick)="print()"
          >
          </dx-button>
        </div>

        <hr class="w-100 my-2" />

        <p>
          <span>İşletme:</span> {{ order.restaurant.name }} /
          {{ order.restaurant.address }}
        </p>

        <p>
          <span>Sipariş Tarihi:</span>
          {{
            order.orderDatetime | convertUtcToLocal | date : "dd.MM.yyyy HH:mm"
          }}
          <span
            class="badge text-white"
            style="color: white !important"
            [ngClass]="
              (order.orderDatetime | convertUtcToLocal | elapsedMinutes) > 30
                ? 'badge-danger'
                : 'badge-secondary'
            "
            >{{
              order.orderDatetime | convertUtcToLocal | elapsedMinutes
            }}
            dk</span
          >
        </p>

        <p *ngIf="order.isAdvanceOrder" class="text-danger">
          <span>İstenilen Teslim Tarihi:</span>
          {{
            order.scheduledDate | convertUtcToLocal | date : "dd.MM.yyyy HH:mm"
          }}
        </p>

        <p><span>Ödeme Yöntemi:</span> {{ order.paymentMethodName }}</p>
        <p><span>Sipariş Durumu:</span> {{ order.statusName }}</p>

        <hr class="w-100 my-2" />

        <div class="d-flex justify-content-between align-items-end">
          <div
            *ngIf="order?.note"
            class="alert alert-dark w-50 mb-0"
            role="alert"
          >
            <b>Müşteri Notu:</b>
            <br />
            {{ order.note }}
          </div>

          <div class="mr-2">
            <dx-button *ngIf="nextStatusName != null" icon="check" type="success" [text]="nextStatusName + ' olarak işaretle'" (onClick)="updateOrderStatus(order)"> </dx-button>
            <dx-button icon="remove" class="ml-2" type="danger" text="Reddet"(onClick)="openCancelReasonModal(order)" >
            </dx-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-1 mt-4">
    <div class="col-md-8">
      <div class="dx-card dx-wide-card py-2 px-3">
        <p class="mb-1 h5">Sipariş İçeriği</p>

        <div class="p-3">
          <hr class="w-100 mb-3" />
          <ng-container *ngFor="let item of order.orderItems">
            <div class="item">
              <div class="position-relative">
                <img [src]="item.itemImage ?? 'assets/placeholder.png'" />
                <span class="badge badge-secondary badge-quantity p-2">{{
                  item.quantity
                }}</span>
              </div>
              <div class="ml-3 w-100">
                <h6 class="text-dark">{{ item.itemName }}</h6>

                <ul *ngIf="item.itemVariations">
                  <ng-container
                    *ngTemplateOutlet="itemVaraitionTemplate; context: { list: item.itemVariations }"
                  ></ng-container>
                </ul>

                <ng-template #itemVaraitionTemplate let-list="list">
                  <li
                    *ngFor="let variation of list"
                    class="text-secondary"
                  >
                    {{ variation.itemAttributeName }}: {{ variation.name }}
                    <b>{{ variation.price | number : "1.2-2" }}</b>

                    <ul *ngIf="variation.basicItemVariations.length > 0">
                      <ng-container
                        *ngTemplateOutlet="itemVaraitionTemplate; context:{ list: variation.basicItemVariations }"
                      ></ng-container>
                    </ul>
                  </li>
                </ng-template>

                <ul *ngIf="item.itemExtras">
                  <li
                    *ngFor="let variation of item.itemExtras"
                    class="text-secondary"
                  >
                    {{ variation.name }}
                    <b>{{ variation.price | number : "1.2-2" }}</b>
                  </li>
                </ul>

                <div
                  *ngIf="item?.instruction"
                  class="alert alert-dark mb-0"
                  role="alert"
                >
                  <b>Müşteri Notu:</b>
                  <br />
                  {{ item.instruction }}
                </div>

                <h6 class="text-dark h7">
                  Toplam: {{ item.totalPrice | number : "1.2-2" }}
                </h6>
              </div>
            </div>

            <hr class="w-100 my-2" />
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="dx-card dx-wide-card py-2 px-3">
        <p class="d-flex justify-content-between">
          <span>Ödeme Yöntemi:</span> {{ order.paymentMethodName }}
        </p>

        <p class="d-flex justify-content-between">
          <span>Ara Toplam:</span>
          {{ order.subtotalPrice | number : "1.2-2" }}
        </p>
        <p class="d-flex justify-content-between text-danger">
          <span>İndirim:</span> -{{ order.discountPrice | number : "1.2-2" }}
        </p>

        <hr class="w-100 my-2" />
        <p class="d-flex justify-content-between">
          <strong>Toplam:</strong> {{ order.totalPrice | number : "1.2-2" }}
        </p>
      </div>

      <div class="dx-card dx-wide-card py-2 px-3 mt-1 mt-4">
        <p class="mb-1 h5">Müşteri Bilgileri</p>
        <hr class="w-100 my-3" />

        <div class="d-flex align-items-center my-2">
          <dx-button icon="user" class="mr-2" [disabled]="true"> </dx-button>
          <p>{{ order.orderAddress.clientFullName }}</p>
        </div>

        <div class="d-flex align-items-center my-2">
          <dx-button icon="tel" class="mr-2" [disabled]="true"> </dx-button>
          <p>{{ order.orderAddress.clientPhoneNumber }}</p>
        </div>

        <div
          *ngIf="order.orderAddress.supportPhoneNumber"
          class="d-flex align-items-center my-2"
        >
          <dx-button icon="help" class="mr-2" [disabled]="true"> </dx-button>
          <p>{{ order.orderAddress.supportPhoneNumber }}</p>
        </div>

        <div class="d-flex align-items-center my-2">
          <dx-button icon="map" class="mr-2" [disabled]="true"> </dx-button>
          <div>
            <p>{{ order.orderAddress.address }}</p>
            <p>{{ order.orderAddress.apartment }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none">
  <div #printComponent *ngIf="order">
    <app-order-print [order]="order"></app-order-print>
  </div>
</div>



<dx-popup
  [width]="400"
  [height]="'100%'"
  [showTitle]="true"
  title="Sipariş İptal"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="cancelPopupVisible"
>
  <dxo-position at="right" my="right" collision="fit"> </dxo-position>

  <dxi-toolbar-item
    toolbar="bottom"
    location="before"
    widget="dxButton"
    [options]="{
      stylingMode: 'contained',
      type: 'success',
      text: 'Kaydet',
      onClick: cancelOrder
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      stylingMode: 'contained',
      type: 'normal',
      text: 'Vazgeç',
      onClick: onCloseClicked
    }"
  >
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">İptal Etme Nedeni*</div>
        <dx-radio-group
          [dataSource]="cancelReasons"
          [(value)]="selectedOrderForCancel.cancelReasonId"
          displayExpr="name"
          valueExpr="id"
        >
        </dx-radio-group>
      </div>

      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">Not</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="selectedOrderForCancel.note"
          [showClearButton]="true"
        ></dx-text-box>
      </div>
    </div>
  </div>
</dx-popup>
