import { CommonModule } from "@angular/common";
import {
  AfterContentChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";
import { HttpService } from "src/app/shared/services/http.service";
import { ItemResourceDto } from "src/app/shared/models/item.model";
import StatusUtils, { Status } from "src/app/shared/models/status.enum";
import OrderStatusUtils, {
  OrderStatus,
} from "src/app/shared/models/order-status.enum";
import { DxTextBoxComponent } from "devextreme-angular";
import { OrderType } from "src/app/shared/models/OrderType";
import { PaymentMethod } from "src/app/shared/models/PaymentMethod";
import { confirm } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { firstValueFrom, map, tap } from "rxjs";
import { ConvertUtcToLocalPipe } from "src/app/shared/pipes/convert-utc-to-local.pipe";
import ProviderUtils, { Provider } from "src/app/shared/models/provider.enum";
import { AuthService, IUser } from "src/app/shared/services";

@Component({
  selector: "app-point-of-sale",
  templateUrl: "point-of-sale.component.html",
  styleUrls: ["./point-of-sale.component.scss"],
})
export class PointOfSaleComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  @ViewChild("categoryHolder") categoryHolder!: ElementRef;
  @ViewChild("itemHolder") itemHolder!: ElementRef;

  currentItem: any;
  currentValue: string = '';
  isCountPopupVisible = false;

  cart: any[] = [];
  cloneCart: any[] = [];
  selectedItem: any;
  variationPopupVisible = false;
  splitOrderPopupVisible = false;
  selectedSplitCart: any[] = [];
  orderTypes = [
    { key: OrderType.Pos, text: "Masa", disabled: false, icon: "verticalaligncenter"},
    { key: OrderType.TakeAway, text: "Self-Servis", disabled: false, icon: 'user' },
    { key: OrderType.Delivery, text: "Adrese Teslim", disabled: true, icon: 'car' },
    { key: OrderType.Handover, text: "Kurye Çağır", disabled: true, icon: 'runner' },
  ];

  paymentMethods = [
    { key: PaymentMethod.CashOnDelivery, text: "Nakit", disabled: false },
    {
      key: PaymentMethod.CreditCardOnDelivery,
      text: "Kredi Kartı",
      disabled: false,
    },
    {
      key: PaymentMethod.CreditCardOnline,
      text: "Online Kredi Kartı",
      disabled: true,
    },
  ];

  selectedOrderType = OrderType.Pos;
  selectedPaymentMethod = PaymentMethod.CreditCardOnDelivery;
  isPaid = false;

  selectedOrderForCancel = { id: null, cancelReasonId: null, note: null };
  cancelPopupVisible = false;
  selectedTableId = null;
  isPaymentPopupVisible = false;
  customerList = [
    { id: 1, name: "John" },
    { id: 2, name: "Walk-in Customer" },
    { id: 3, name: "Jane" },
  ];
  selectedCustomerId = 1;

  customerTypeList = [
    { id: 1, name: "Regular" },
    { id: 2, name: "VIP" },
  ];

  activeOrderPopupVisible = false;
  selectedCustomerTypeId = 1;
  selectedOrderTypeIndex = 0;
  //OrderDetail

  menu: any;
  availableCategories: string[] = [];
  filteredItems: ItemResourceDto[] = [];
  readonly allKey = "Tümü";
  selectedCategory = this.allKey;
  categoryPopupVisible = false;

  orderDataSource = [];
  lookupStatus = OrderStatusUtils.StatusNamesLookup;

  allTables = [];
  isDragAdded;
  isDragAddedToItems;

  enableImages = false;

  order: any = {};

  receivedAmount = 0;
  selectableTables = [];
  beepSound = false;

  selectedOrderProviderFilter: number = Provider.Pos;  // Default selection
  user: IUser;

  get totalPayable(): number {
    return this.cart.reduce((acc, item) => acc + item.totalPrice, 0);
  }

  constructor(
    public location: Location,
    private httpService: HttpService,
    private convertUtcToLocale: ConvertUtcToLocalPipe,
    private authService: AuthService
  ) {}


  ngOnInit(): void {
    this.authService.getUser().then((e) => {
      this.user = e.data;
      console.log(this.user);
    });

    document.body.classList.add("pos-screen");
    this.init();
    this.getMenu();

    document.addEventListener(
      "click",
      () => {
        this.enterFullscreen();
      },
      { once: true }
    );
  }

  init() {
    this.getTables();
    this.generateNewOrderModel();
    this.getActiveOrders();
  }

  getActiveOrders(): Promise<any> {
    return firstValueFrom(
      this.httpService
        .get("Order", {
          params: OrderStatusUtils.GetOrderAndStatusParams(
            [...OrderStatusUtils.ActiveOrderStatuses, ...OrderStatusUtils.NewOrderStatuses]
          ),
        })
        .pipe(
          map((x) => {
            x.forEach((element) => {
              element.orderDatetime = this.convertUtcToLocale.transform(
                element.orderDatetime
              );
              element.paymentMethodName =
                element.paymentMethodName ?? "Online Ödeme";
            });
            return x;
          }),
          tap((x) => {
            this.orderDataSource = x;
            this.filterTables();
          })
        )
    );
  }

  filterTables() {
    const activeTables = this.orderDataSource
      .filter((x) => x.tableId != null)
      .map((x) => x.tableId);
    this.selectableTables = this.allTables.filter(
      (x) => !activeTables.includes(x.id)
    );
  }

  ngAfterContentChecked(): void {
    if (this.categoryHolder && !this.isDragAdded) {
      this.isDragAdded = true;
      this.enableDragScroll(this.categoryHolder.nativeElement);
    }

    if (this.itemHolder && !this.isDragAddedToItems) {
      this.isDragAddedToItems = true;
      this.enableVerticalDragScroll(this.itemHolder.nativeElement);
    }
  }

  ngOnDestroy(): void {
    document.body.classList.remove("pos-screen");
  }

  enableDragScroll(container: HTMLElement): void {
    let isDragging = false;
    let startX = 0;
    let scrollLeft = 0;

    container.addEventListener("mousedown", (e: MouseEvent) => {
      isDragging = true;
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
      container.style.cursor = "grabbing";
    });

    container.addEventListener("mouseleave", () => {
      isDragging = false;
      container.style.cursor = "grab";
    });

    container.addEventListener("mouseup", () => {
      isDragging = false;
      container.style.cursor = "grab";
    });

    container.addEventListener("mousemove", (e: MouseEvent) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const distance = x - startX;
      container.scrollLeft = scrollLeft - distance;
    });

    // Touch events for smooth scrolling on mobile
    container.addEventListener("touchstart", (e: TouchEvent) => {
      startX = e.touches[0].pageX;
      scrollLeft = container.scrollLeft;
    });

    container.addEventListener("touchmove", (e: TouchEvent) => {
      if (!e.cancelable) return; // Avoid blocking other gestures
      const x = e.touches[0].pageX;
      const distance = x - startX;
      container.scrollLeft = scrollLeft - distance;
    });
  }

  enableVerticalDragScroll(container: HTMLElement): void {
    let isDragging = false;
    let startY: number;
    let scrollTop: number;

    // Mouse events for desktop
    container.addEventListener("mousedown", (e: MouseEvent) => {
      isDragging = true;
      startY = e.clientY;
      scrollTop = container.scrollTop;
      container.style.cursor = "grabbing";
    });

    container.addEventListener("mouseleave", () => {
      isDragging = false;
      container.style.cursor = "grab";
    });

    container.addEventListener("mouseup", () => {
      isDragging = false;
      container.style.cursor = "grab";
    });

    container.addEventListener("mousemove", (e: MouseEvent) => {
      if (!isDragging) return;
      e.preventDefault(); // Prevent text selection while dragging
      const move = e.clientY - startY;
      container.scrollTop = scrollTop - move;
    });

    // Touch events for mobile
    container.addEventListener("touchstart", (e: TouchEvent) => {
      const touch = e.touches[0];
      startY = touch.clientY;
      scrollTop = container.scrollTop;
      container.style.cursor = "grabbing";
      isDragging = true; // Enable dragging on touch start
    });

    container.addEventListener("touchmove", (e: TouchEvent) => {
      if (!isDragging) return;
      e.preventDefault(); // Prevent default touch actions (e.g., zooming)
      const touch = e.touches[0];
      const move = touch.clientY - startY;
      container.scrollTop = scrollTop - move;
    });

    container.addEventListener("touchend", () => {
      isDragging = false;
      container.style.cursor = "grab";
    });

    container.addEventListener("touchcancel", () => {
      isDragging = false;
      container.style.cursor = "grab";
    });
  }


  // Method to move items between tables
  moveToSelected(item: any) {    
    item.quantity -= 1;
    if (item.quantity <= 0) {
      this.cloneCart = this.cloneCart.filter((x) => x.id !== item.id);
    }

    const existingItem = this.selectedSplitCart.find(x => x.id == item.id);
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      this.selectedSplitCart.push({...item, quantity: 1})
    }

    this.receivedAmount = Math.ceil(this.calculateSplitOrderTotal()/10) * 10;
  }

  moveBackToOrder(item: any) {
    item.quantity -= 1;
    if (item.quantity <= 0) {
      this.selectedSplitCart = this.selectedSplitCart.filter((x) => x.id !== item.id);
    }
  
    const existingItem = this.cloneCart.find((x) => x.id == item.id);
    if (existingItem) {
      existingItem.quantity += 1;
    } else {
      this.cloneCart.push({ ...item, quantity: 1 });
    }

    this.receivedAmount = Math.ceil(this.calculateSplitOrderTotal()/10) * 10;
  }

  calculateSplitOrderTotal() {
    return this.selectedSplitCart.reduce((sum, item) => sum + ((item.price + item.itemVariationTotal + item.itemExtraTotal) * item.quantity), 0);
  }

  async submitSplitOrder() {
    console.log(JSON.stringify(this.selectedSplitCart));
    if (this.selectedSplitCart.length == 0) {
      return;
    }

    let result = await confirm(
      `İşlemi tamamlamak istediğinize emin misiniz?`,
      "Onayı"
    );

    if (!result) {
      return;
    }

    const orderId = this.selectedSplitCart[0]?.orderId; // Tüm öğeler aynı OrderId'ye sahip
    const itemsToSplit = this.selectedSplitCart.map(item => ({
      item1: item.id,
      item2: item.quantity,
    }));
  
    const body = {
      orderId: orderId,
      itemsToSplit: itemsToSplit,
      paymentMethod: this.selectedPaymentMethod, 
      paymentStatus: this.isPaid ? 0 : 1, 
    };


    this.httpService.post("Order/split", body).subscribe((res) => {
      this.getOrderDetailClicked(body.orderId)
      this.closeSplitOrderPopup();
    });
  }

  closeSplitOrderPopup() {
    this.splitOrderPopupVisible = false;
    this.cloneCart = [];
  }

  openSplitOrderPopup() {
    this.selectedSplitCart = [];
    this.cloneCart = [...this.cart.filter(x => !!x.id)];
    this.isPaid = true;
    this.splitOrderPopupVisible = true;
  }



  onCheckboxValueChanged = function (e) {
    this.enableImages = e.value;
  }.bind(this);

  onBeepSoundChanged = function (e) {
    this.beepSound = e.value;
  }.bind(this);

  toggleFullscreen = function (e) {
    if (!document.fullscreenElement) {
      this.enterFullscreen();
    } else {
      this.exitFullscreen();
    }
  }.bind(this);

  getStatusName(orderStatus: OrderStatus): string {
    const found = this.lookupStatus.find((s) => s.id === orderStatus);
    return found ? found.name : "";
  }

  getMenu() {
    ProviderUtils.ProviderColumnNameDictionary
    this.httpService.get("Item/menu").subscribe((res) => {
      this.availableCategories = [this.allKey, ...Object.keys(res)];

      let allItems = [];
      Object.keys(res).forEach((key) => {
        allItems = allItems.concat(...res[key]);
      });

      res[this.allKey] = allItems;
      this.menu = res;
      this.filteredItems = allItems;
    });
  }

  getTables() {
    this.httpService
      .get(`Table`, { params: StatusUtils.GetStatusParams([Status.Active]) })
      .subscribe((res) => {
        this.allTables = res;
      });
  }

  changeCategory(selection) {
    this.selectedCategory = selection;
    this.filteredItems = this.menu[selection];
  }

  filterByName(event: any) {
    const searchValue = event.event.target.value;

    if (!searchValue || searchValue.length == 0) {
      return this.changeCategory(this.selectedCategory);
    }
    this.filteredItems = (this.menu[this.selectedCategory] as any[]).filter(
      (x) =>
        (x.name as string)
          .toLocaleLowerCase()
          .includes((searchValue as string).toLocaleLowerCase())
    );
  }

  enterFullscreen(): void {
    const element = document.documentElement; // Get the full document to make it fullscreen
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if ((element as any).webkitRequestFullscreen) {
      // Safari
      (element as any).webkitRequestFullscreen();
    } else if ((element as any).msRequestFullscreen) {
      // IE/Edge
      (element as any).msRequestFullscreen();
    }
  }

  exitFullscreen(): void {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
      (document as any).msExitFullscreen();
    }
  }

  onOrderTypeChange(e: any) {
    this.selectedOrderTypeIndex = e.itemIndex;
    this.selectedOrderType = this.orderTypes[e.itemIndex].key;

    if (this.selectedOrderTypeIndex != 0) {
      this.selectedTableId = null;
    }

    this.order.OrderType = this.selectedOrderType;
  }

  getOrderDetailClicked(e: any) {
    this.httpService.get(`order/${e}`).subscribe((res) => {
      if (res.tableId) {
        this.selectedTableId = res.tableId;
        this.selectedOrderTypeIndex = 0;
        this.selectedOrderType = this.orderTypes[0].key;
      }

      this.cart = [...res.orderItems];
      this.order = res;
      this.isPaid = res.paymentStatus == 0;

      this.activeOrderPopupVisible = false;
    });
  }

  onItemClick(item: any): void {
    this.playBeep();
    if (
      (!item.variations || item.variations.length === 0) &&
      (!item.extras || item.extras.length === 0)
    ) {
      this.addToCart(item, [], []);
    } else {
      this.selectedItem = item;
      if (this.selectedItem.itemAttributes) {
        this.selectedItem.itemAttributes = this.selectedItem.itemAttributes.map(
          (x) => {
            const mappedVariations = this.getVariationsByAttribute(x.id);
            return {
              ...x,
              selectedVariation: mappedVariations[0].id,
              mappedVariations: mappedVariations,
            };
          }
        );
      }

      if (this.selectedItem.extras) {
        this.selectedItem.extras = this.selectedItem.extras.map((x) => {
          return {
            ...x,
            selected: false,
          };
        });
      }

      this.variationPopupVisible = true;
    }
  }

  playBeep() {
    if (!this.beepSound) {
      return;
    }

    const context = new AudioContext();
    const oscillator = context.createOscillator();
    oscillator.type = 'sine'; // Type of sound wave
    oscillator.frequency.setValueAtTime(440, context.currentTime); // Frequency in Hz
    oscillator.connect(context.destination);
    oscillator.start();
    oscillator.stop(context.currentTime + 0.1); // Stop sound after 0.1 seconds
  }

  // Add to cart with selected variations and extras
  addToCart(item: any, variations: any[], extras: any[], quantity = 1): void {
    const existingItem = this.cart.find(
      (cartItem) =>
        cartItem.itemId === item.id &&
        JSON.stringify(cartItem.itemVariations) ===
          JSON.stringify(variations) &&
        JSON.stringify(cartItem.itemExtras) === JSON.stringify(variations)
    );

    if (existingItem) {
      existingItem.quantity += quantity;
      existingItem.totalPrice = this.calculateItemTotal(
        { price: existingItem.price },
        existingItem.itemVariations,
        existingItem.itemExtras,
        existingItem.quantity
      );
    } else {
      this.cart.push({
        itemId: item.id,
        itemName: item.name,
        price: item.price,
        discount: 0,
        itemExtraTotal:this.getExtraTotal(extras),
        itemVariationTotal: this.getVariationsTotal(variations),
        itemVariations: variations,
        itemExtras: extras,
        quantity,
        totalPrice: this.calculateItemTotal(item, variations, extras, quantity),
      });
    }
  }

  getExtraTotal(extras) {
    const extraTotal = extras.reduce((sum, e) => sum + e.price, 0);
    return extraTotal;
  }

  getVariationsTotal(variations: any[]) {
    const variationTotal = variations.reduce((sum, v) => sum + v.price, 0);
    return variationTotal;
  }

  calculateItemTotal(
    item: any,
    variations: any[],
    extras: any[],
    quantity: number
  ): number {
    const variationTotal = this.getVariationsTotal(variations);
    const extraTotal = this.getExtraTotal(extras);
    return (item.price + variationTotal + extraTotal) * quantity;
  }

  onVariationConfirm(selectedVariations: any[]): void {
    this.addToCart(
      this.selectedItem,
      selectedVariations,
      this.selectedItem.extras?.filter((x) => x.selected)
    );
    this.variationPopupVisible = false;
  }

  adjustQuantity(cartItem: any, delta: number): void {
    cartItem.quantity += delta;
    if (cartItem.quantity <= 0) {
      this.cart = this.cart.filter((item) => item !== cartItem);
    } else {
      cartItem.totalPrice = this.calculateItemTotal(
        { price: cartItem.price },
        cartItem.itemVariations,
        cartItem.itemExtras,
        cartItem.quantity
      );
    }
  }

  getTableName() {
    const table = this.allTables.find((x) => x.id == this.selectedTableId);
    if (table == null) {
      return "MASA SEÇİLMEDİ";
    }

    return table.number + " - " + table.name;
  }

  async onSubmitClicked() {
    debugger;
    this.order.orderType = this.selectedOrderType;
    if (this.order.orderType == OrderType.Pos && this.selectedTableId == null) {

      notify(
        {
          message: "Masa Zorunludur.",
          width: 450,
        },
        "error",
        2000
      );

      return;
    }

    let result = await confirm(
      `Sipariş oluşturmak istediğinize emin misiniz??`,
      "Sipariş Onayı"
    );

    if (!result) {
      return;
    }

    const postBody = {
      ...this.order,
      subtotal: this.cart.reduce((sum, item) => sum + item.totalPrice, 0),
      total: this.cart.reduce((sum, item) => sum + item.totalPrice, 0),
      orderType: this.selectedOrderType,
      tableId: this.selectedTableId,
      items: this.cart.map((cartItem) => ({
        itemId: cartItem.itemId,
        itemName: cartItem.itemName,
        quantity: cartItem.quantity,
        price: cartItem.price,
        itemVariations: cartItem.itemVariations??[],
        itemExtras: cartItem.itemExtras??[],
        itemExtraTotal:this.getExtraTotal(cartItem.itemExtras??[]),
        itemVariationTotal: this.getVariationsTotal(cartItem.itemVariations??[]),
        totalPrice: cartItem.totalPrice,
      })),
      paymentMethod: this.selectedPaymentMethod,
      paymentStatus: this.isPaid ? 0 : 1,
    };

    this.httpService.post("Order", postBody).subscribe((res) => {
      if (this.selectedOrderTypeIndex == 1) {
        this.order = res;
        this.openPaymentPopup();
        return;
      }

      this.cart = [];
      this.init();
    });
  }

  getVariationsByAttribute(attributeId: string): any[] {
    return this.selectedItem.variations
      .filter((v) => v.itemAttributeId === attributeId)
      .map((x) => {
        return {
          ...x,
          name: `${x.name} (+${x.price.toFixed(2)})`,
        };
      });
  }

  getSelectedVariations(): any[] {
    return this.selectedItem.itemAttributes
      .filter((attr) => attr.selectedVariation)
      .map((attr) => {
        const variation = this.selectedItem.variations.find(
          (v) => v.id === attr.selectedVariation
        );
        return {
          id: variation.id,
          name: `${variation.name} (+${variation.price.toFixed(2)})`,
          price: variation.price,
          itemAttributeName: attr.name,
          basicItemVariations: [],
        };
      });
  }

  async onCancelClicked() {
    if (!this.order.id) {
      let result = await confirm(
        `Siparişi <b>iptal</b> etmek istediğinize emin misiniz??`,
        "Sipariş İptal Onayı"
      );

      if (!result) {
        return;
      }

      this.cart = [];
      this.generateNewOrderModel();

      return;
    }
    this.cancelPopupVisible = true;
  }

  onCloseClicked = function () {
    this.cancelPopupVisible = false;
    this.selectedOrderForCancel = null;
  }.bind(this);

  cancelOrder = async function () {
    let result = await confirm(
      `Siparişi <b>iptal</b> etmek istediğinize emin misiniz??`,
      "Sipariş İptal Onayı"
    );

    if (!result) {
      return;
    }


  if(this.selectedOrderForCancel.note == null)
  {
    notify(
      {
        message: "İptal Nedeni Zorunludur.",
        width: 450,
      },
      "error",
      2000
    );
    return;
  }


    this.httpService
      .put("Order", {
        orderId: this.order.id,
        orderStatus: OrderStatus.OrderCancelledByRestaurant,
        cancelReasonId: null,
        cancelReasonText: this.selectedOrderForCancel.note,
      })
      .subscribe(
        (res) => {
          this.cart = [];
          this.init();
          this.cancelPopupVisible = false;
        },
        (e) => {
          notify(
            {
              message: "İşlem başarısız oldu.",
              width: 450,
            },
            "error",
            2000
          );
        }
      );
  }.bind(this);

  sendOrderStatusUpdate() {
    this.httpService
    .put("Order", {
      orderId: this.order.id,
      orderStatus: OrderStatus.OrderDelivered,
      paymentMethod: this.selectedPaymentMethod,
      paymentStatus: this.isPaid ? 0 : 1,
    })
    .subscribe(
      (res) => {
        this.cart = [];
        this.init();
        this.isPaymentPopupVisible = false;
      },
      (e) => {
        notify(
          {
            message: "İşlem başarısız oldu.",
            width: 450,
          },
          "error",
          2000
        );
      }
    );
  }

  generateNewOrderModel() {
    this.isPaid = false;
    this.selectedTableId = null;

    this.order = {
      subtotal: this.cart.reduce((sum, item) => sum + item.totalPrice, 0),
      discount: 0,
      deliveryCharge: 0,
      total: this.cart.reduce((sum, item) => sum + item.totalPrice, 0),
      orderType: this.selectedOrderType,
      isAdvanceOrder: false,
      addressId: null,
      deliveryTime: null,
      couponId: null,
      tableId: null,
      items: this.cart.map((cartItem) => ({
        itemId: cartItem.itemId,
        itemName: cartItem.itemName,
        quantity: cartItem.quantity,
        price: cartItem.price,
        itemVariations: cartItem.itemVariations,
        itemExtraTotal:this.getExtraTotal(cartItem.itemExtras??[]),
        itemVariationTotal: this.getVariationsTotal(cartItem.itemVariations??[]),
        itemExtras: cartItem.itemExtras,
        totalPrice: cartItem.totalPrice,
      })),
      paymentMethod: this.selectedPaymentMethod,
      paymentStatus: 1,
      id: null,
    };
  }

  openPaymentPopup() {
    this.isPaid = true;
    this.receivedAmount = Math.ceil(this.totalPayable/10) * 10;
    this.isPaymentPopupVisible = true;
  }

  openCountPopup(item: any) {
    this.isCountPopupVisible = true;
    this.currentItem = item;
    this.currentValue = item.quantity.toString();
  }

  appendNumber(num: string) {
    this.currentValue += num;
  }

  clearInput() {
    this.currentValue = '';
  }

  applyInput() {
    let newCount = parseInt(this.currentValue, 10);

    if (newCount <= 0 || !newCount) {
      return;
    }

    this.currentItem.quantity = 0;
    this.adjustQuantity(this.currentItem, parseInt(this.currentValue, 10));
    this.isCountPopupVisible = false;
  }

  removeLastDigit() {
    this.currentValue = this.currentValue.slice(0, -1); // Remove the last digit
  }

  back = function () {
    this.location.back();
  }.bind(this);
}
