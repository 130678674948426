import { Component } from "@angular/core";
import notify from "devextreme/ui/notify";
import { SalesInfoRequestDto } from "src/app/shared/models/dashboard.model";
import { HttpService } from "src/app/shared/services/http.service";

const msInDay = 1000 * 60 * 60 * 24;
const now = new Date();
let tenDaysAgo = new Date(now.getTime() - msInDay * 10);
if (tenDaysAgo.getDate() < 1) {
  tenDaysAgo.setDate(1);
}

const initialValue: [Date, Date] = [tenDaysAgo, now];

@Component({
  templateUrl: "home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  currentValue: any = initialValue;

  tabsWithText: any[] = [
    {
      id: 0,
      text: "Günlük",
    },
    {
      id: 1,
      text: "Haftalık",
    },
    {
      id: 2,
      text: "Aylık",
    },
    {
      id: 3,
      text: "Yıllık",
    },
    {
      id: 4,
      text: "Tüm Zamanlar",
    },
  ];
  salesInfoRequestDto: SalesInfoRequestDto = new SalesInfoRequestDto();
  tabsSelectedIndex: any = 0;
  salesInfo: any[] = [];
  saleChannels: any[] = [];
  totalSales: number = 0;
  totalOrders: number = 0;
  totalDiscount: number = 0;
  totalTax: number = 0;

  /*salesInfo: any[] = [
    {
      title: "Ocak",
      pos: 598,
      yemeksepeti: 37.6,
      siparissaati: 182,
      getir: 164.3,
      trendyol: 287.9,
    },
    {
      title: "Şubat",
      pos: 899.8,
      yemeksepeti: 237.6,
      siparissaati: 482,
      getir: 364.3,
      trendyol: 187.9,
    },
    {
      title: "Mart",
      pos: 1598,
      yemeksepeti: 537.6,
      siparissaati: 382,
      getir: 164.3,
      trendyol: 287.9,
    },
    {
      title: "Nisan",
      pos: 559.8,
      yemeksepeti: 237.6,
      siparissaati: 382,
      getir: 564.3,
      trendyol: 187.9,
    },
    {
      title: "Mayıs",
      pos: 59.8,
      yemeksepeti: 337.6,
      siparissaati: 482,
      getir: 564.3,
      trendyol: 187.9,
    },
    {
      title: "Haziran",
      pos: 509.8,
      yemeksepeti: 737.6,
      siparissaati: 382,
      getir: 164.3,
      trendyol: 287.9,
    },
    {
      title: "Temmuz",
      pos: 590.8,
      yemeksepeti: 937.6,
      siparissaati: 582,
      getir: 564.3,
      trendyol: 187.9,
    },
    {
      title: "Ağustos",
      pos: 59.8,
      yemeksepeti: 937.6,
      siparissaati: 582,
      getir: 564.3,
      trendyol: 187.9,
    },
    {
      title: "Eylül",
      pos: 59.8,
      yemeksepeti: 937.6,
      siparissaati: 582,
      getir: 564.3,
      trendyol: 187.9,
    },
    {
      title: "Ekim",
      pos: 208,
      yemeksepeti: 940,
      siparissaati: 300,
      getir: 500,
      trendyol: 10,
    },
    {
      title: "Kasım",
      pos: 900,
      yemeksepeti: 937.6,
      siparissaati: 100,
      getir: 564.3,
      trendyol: 87,
    },
    {
      title: "Aralık",
      pos: 598,
      yemeksepeti: 937,
      siparissaati: 82,
      getir: 564,
      trendyol: 189,
    },
  ];*/

  /*saleChannels: any[] = [
    {
      title: "Pos",
      total: 12000,
    },
    {
      title: "Yemek Sepeti",
      total: 5000,
    },
    {
      title: "Sipariş Saati",
      total: 2320,
    },
    {
      title: "Getir",
      total: 3000,
    },
    {
      title: "Trendyol",
      total: 1275,
    },
  ];*/

  /*mostSellingItems: any[] = [
    {
      ID: 1,
      "Ürün": "Tavuk Döner Dürüm Menü 75gr",
      "Sipariş Sayısı": 250,
      "Toplam Tutar": 2375
    },
    {
      ID: 2,
      "Ürün": "Et Döner Dürüm Menü 75gr",
      "Sipariş Sayısı": 125,
      "Toplam Tutar": 2500
    },
    {
      ID: 3,
      "Ürün": "Ayran",
      "Sipariş Sayısı": 120,
      "Toplam Tutar": 650
    },
    {
      ID: 4,
      "Ürün": "Hamburger 150gr",
      "Sipariş Sayısı": 100,
      "Toplam Tutar": 3000
    },
  ];*/
  constructor(private httpService: HttpService) {
    this.clearSalesInfo();
    const startDate = initialValue[0];
    const startUtcDate = new Date(Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0, 0, 0
    ));
    const endDate = initialValue[1];
    const endUtcDate = new Date(Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      0, 0, 0
    ));
    
    this.salesInfoRequestDto.startDate = startUtcDate;
    this.salesInfoRequestDto.endDate = endUtcDate;
    this.onTabsSelectionChanged();
  }
  customizeTooltip = ({ percentText, valueText }) => ({
    text: `${percentText ?? "Toplam"} - ${valueText} TL`,
  });

  customizeLabel(arg) {
    return `${arg.valueText}TL (${arg.percentText})`;
  }

  onDateChanged(data) {
    this.clearSalesInfo();
    const startDate = data.value[0];
    const startUtcDate = new Date(Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0, 0, 0
    ));
    const endDate = data.value[1];
    const endUtcDate = new Date(Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      0, 0, 0
    ));
    
    this.salesInfoRequestDto.startDate = startUtcDate;
    this.salesInfoRequestDto.endDate = endUtcDate;
    this.getSalesInfo();
  }

  onTabsSelectionChanged() {
    this.clearSalesInfo();
    let salesInfoRequestDto = new SalesInfoRequestDto();
    if(this.tabsSelectedIndex == 0) {
      salesInfoRequestDto.reportFormat = "Daily";
    }
    else if(this.tabsSelectedIndex == 1) {
      salesInfoRequestDto.reportFormat = "Weekly";
    }
    else if(this.tabsSelectedIndex == 2) {
      salesInfoRequestDto.reportFormat = "Montly";
    }
    else if(this.tabsSelectedIndex == 3) {
      salesInfoRequestDto.reportFormat = "Yearly";
    }
    else if(this.tabsSelectedIndex == 4) {
      salesInfoRequestDto.reportFormat = "AllTime";
    }
    this.getSalesInfo();
  }

  getSalesInfo() {
    if (!this.salesInfoRequestDto.startDate || !this.salesInfoRequestDto.endDate || isNaN(this.salesInfoRequestDto.startDate.getTime()) || isNaN(this.salesInfoRequestDto.endDate.getTime())) {
      return null; // Geçerli değerler yoksa validasyonu çalıştırmayın
    }

    if(this.tabsSelectedIndex == 0) {
      this.salesInfoRequestDto.reportFormat = "Daily";
      if (this.salesInfoRequestDto.startDate.getFullYear() !== this.salesInfoRequestDto.endDate.getFullYear() || this.salesInfoRequestDto.startDate.getMonth() !== this.salesInfoRequestDto.endDate.getMonth()) {
        notify(
          {
            message: "Günlük rapor formatında tarihler aynı ay içinde olmalıdır.",
            width: 450,
          },
          "error",
          2000
        );
        return;
      }
    }
    else if(this.tabsSelectedIndex == 1) {
      this.salesInfoRequestDto.reportFormat = "Weekly";
      const diffInMonths = (this.salesInfoRequestDto.endDate.getFullYear() - this.salesInfoRequestDto.startDate.getFullYear()) * 12 + (this.salesInfoRequestDto.endDate.getMonth() - this.salesInfoRequestDto.startDate.getMonth());
      if (diffInMonths > 3 || diffInMonths < 0) {
        notify(
          {
            message: "Haftalık rapor formatında tarihler maksimum 3 ay aralığında olmalıdır.",
            width: 450,
          },
          "error",
          2000
        );
        return;
      }
    }
    else if(this.tabsSelectedIndex == 2) {
      this.salesInfoRequestDto.reportFormat = "Montly";
      if (this.salesInfoRequestDto.startDate.getFullYear() !== this.salesInfoRequestDto.endDate.getFullYear()) {
        return { invalidDateRange: 'Aylık rapor formatında tarihler aynı yıl içinde olmalıdır.' };
      }
    }
    else if(this.tabsSelectedIndex == 3) {
      this.salesInfoRequestDto.reportFormat = "Yearly";
    }
    else if(this.tabsSelectedIndex == 4) {
      this.salesInfoRequestDto.reportFormat = "AllTime";
    }

    this.httpService.post('Dashboard/SaleInfo', this.salesInfoRequestDto).subscribe(res => {
      this.salesInfo = res.salesResourceDto;
      this.saleChannels = res.saleChannelsResourceDto;
      this.totalSales = res.totalSales;
      this.totalOrders = res.totalOrders;
      this.totalDiscount = res.totalDiscount;
      this.totalTax = res.totalTax;
    });
  }

  clearSalesInfo() {
    this.salesInfo = [];
  }
}
