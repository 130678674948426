<dx-popup
  class="pos-screen"
  [width]="'100%'"
  [height]="'100vh'"
  [showTitle]="true"
  [dragEnabled]="false"
  [showTitle]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="false"
  container=".dx-viewport"
  [visible]="true"
>
  <dxi-toolbar-item
    toolbar="top"
    location="before"
    widget="dxButton"
    [options]="{
      type: 'normal',
      icon: 'fullscreen',
      onClick: toggleFullscreen
    }"
  ></dxi-toolbar-item>

  <dxi-toolbar-item toolbar="top" location="before">
    <div class="font-weight-bold px-2">
      {{ user?.restaurantName }}
    </div>
  </dxi-toolbar-item>

  <dxi-toolbar-item toolbar="top" location="before">
    <div class="badge-container">
      <button
        type="button"
        class="btn btn-light"
        style="font-size: small"
        (click)="activeOrderPopupVisible = true"
      >
        <i class="dx-icon-activefolder"></i> Aktif Siparişler
      </button>
      <div class="badge">{{ orderDataSource.length }}</div>
    </div>
  </dxi-toolbar-item>

  <dxi-toolbar-item toolbar="top" location="after"> </dxi-toolbar-item>

  <dxi-toolbar-item toolbar="top" location="after">
    <div class="tabs-menu">
      <dx-tabs
        [dataSource]="orderTypes"
        iconPosition="start"
        [selectedIndex]="selectedOrderTypeIndex"
        (onItemClick)="onOrderTypeChange($event)"
      >
      </dx-tabs>
    </div>
  </dxi-toolbar-item>

  <dxi-toolbar-item toolbar="top" location="after"> </dxi-toolbar-item>

  <dxi-toolbar-item
    toolbar="top"
    location="after"
    widget="dxCheckBox"
    [options]="{
      text: 'Beep Ses',
      value: beepSound,
      onValueChanged: onBeepSoundChanged
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    toolbar="top"
    location="after"
    widget="dxCheckBox"
    [options]="{
      text: 'Ürün Görsel',
      value: enableImages,
      onValueChanged: onCheckboxValueChanged
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item toolbar="top" location="after"> </dxi-toolbar-item>

  <dxi-toolbar-item
    toolbar="top"
    location="after"
    widget="dxButton"
    [options]="{
      type: 'normal',
      icon: 'close',
      onClick: back
    }"
  >
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'">
    <div *ngIf="availableCategories.length > 0" class="row">
      <div class="col-md-7">
        <div class="card shadow-sm border-radious">
          <div class="card-body p-0">
            <div class="card-header py-0 px-0">
              <div class="col-12 mb-2 px-0">
                <dx-text-box
                  [placeholder]="'Ara'"
                  (onInput)="filterByName($event)"
                ></dx-text-box>
              </div>

              <div
                #categoryHolder
                class="col-md-12 border category_holder px-0"
              >
                <dx-button
                  class="mt-2 ml-1"
                  [stylingMode]="'contained'"
                  type="normal"
                  icon="menu"
                  (onClick)="categoryPopupVisible = true"
                >
                </dx-button>

                <dx-button
                  *ngFor="let item of availableCategories"
                  class="my-2"
                  [text]="item"
                  [stylingMode]="
                    selectedCategory == item ? 'contained' : 'outlined'
                  "
                  type="default"
                  (onClick)="changeCategory(item)"
                  (mousedown)="$event.stopPropagation()"
                  (mousemove)="$event.stopPropagation()"
                >
                </dx-button>
              </div>
            </div>

            <div class="">
              <div class="col-md-12 px-0">
                <div
                  #itemHolder
                  class="item_holder full_height_item_holder mr-3"
                  style="user-select: none"
                >
                  <div class="category_items">
                    <div class="specific_category_items_holder mr-3">
                      <div
                        [id]="'item-card-' + item.id"
                        class="single_item"
                        (click)="onItemClick(item)"
                        *ngFor="let item of filteredItems"
                      >
                        <img
                          *ngIf="enableImages"
                          [src]="item?.image ?? 'assets/placeholder.png'"
                          width="142"
                        />
                        <div
                          class="d-flex justify-content-between w-100"
                          style="min-height: 45px"
                        >
                          <p class="item_name">
                            {{ item.name }}

                            <dx-tooltip
                              [target]="'#item-card-' + item.id"
                              showEvent="mouseenter"
                              position="right"
                              hideEvent="mouseleave"
                              [hideOnOutsideClick]="false"
                            >
                              <div *dxTemplate="let data = data; of: 'content'">
                                {{ item.name }}
                              </div>
                            </dx-tooltip>
                          </p>
                          <p class="item_price">
                            <span class="price">{{
                              item.price | number : "1.2"
                            }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-5 px-0">
        <div class="card shadow-sm border-radious">
          <div class="card-body p-0">
            <div
              class="order-detail-container bg-white pb-2 pr-2 d-flex flex-column full_height"
            >
              <div
                *ngIf="selectedOrderTypeIndex == 0"
                class="d-flex align-items-center mb-2"
              >
                <dx-select-box
                  class="w-100"
                  [dataSource]="order?.id ? allTables: selectableTables"
                  [(value)]="selectedTableId"
                  valueExpr="id"
                  [readOnly]="order?.id"
                  displayExpr="name"
                  placeholder="Masa Seç"
                  stylingMode="outlined"
                  [showClearButton]="false"
                >
                </dx-select-box>
              </div>

              <dx-scroll-view
                width="100%"
                class="w-100"
                showScrollbar="true"
                [scrollByContent]="true"
                [scrollByThumb]="true"
                [bounceEnabled]="false"
                [useNative]="false"
              >
                <!-- Order Items List -->
                <div class="order-items">
                  <ng-container *ngFor="let item of cart">
                    <div class="order-item">
                      <!-- Item Name -->
                      <div class="item-details">
                        <p class="item-name text-dark">
                          {{ item.itemName }}
                          <ng-container
                            *ngIf="
                              item.itemVariationTotal > 0 ||
                              item.itemExtraTotal > 0
                            "
                          >
                            <br />
                            <span
                              class="badge bg-dark text-white px-2 py-1 mr-1"
                              >{{ item.price | number : "1.2-2" }}</span
                            >
                            <span
                              class="badge bg-success text-white px-2 py-1 mr-1"
                              *ngIf="item.itemVariationTotal > 0"
                              >+{{
                                item.itemVariationTotal | number : "1.2-2"
                              }}</span
                            >
                            <span
                              class="badge bg-danger text-white px-2 py-1 mr-1"
                              *ngIf="item.itemExtraTotal > 0"
                              >+{{
                                item.itemExtraTotal | number : "1.2-2"
                              }}</span
                            >
                          </ng-container>
                        </p>
                        <div *ngIf="item?.instruction" class="instruction">
                          <b>Müşteri Notu:</b>
                          <br />
                          {{ item.instruction }}
                        </div>
                      </div>

                      <!-- Price Details -->
                      <div class="price-details text-right">
                        <div>
                          {{
                            item.price +
                              item.itemExtraTotal +
                              item.itemVariationTotal | number : "1.2-2"
                          }}
                        </div>
                        <div class="text-danger">
                          -{{ item.discount | number : "1.2-2" }}
                        </div>
                      </div>

                      <div class="quantity-control dx-field mb-0">
                        <!-- Decrease Button -->
                        <dx-button
                          [icon]="'minus'"
                          type="danger"
                          stylingMode="contained"
                          (onClick)="adjustQuantity(item, -1)"
                          width="30"
                          height="30"
                          [elementAttr]="{ style: 'border-radius: 4px;' }"
                        >
                        </dx-button>

                        <div
                          class="quantity d-flex align-items-center text-right"
                          style="height: 36px"
                          (click)="openCountPopup(item)"
                        >
                          {{ item.quantity }}
                        </div>

                        <!-- Increase Button -->
                        <dx-button
                          [icon]="'plus'"
                          type="success"
                          stylingMode="contained"
                          (onClick)="adjustQuantity(item, +1)"
                          width="30"
                          height="30"
                          [elementAttr]="{ style: 'border-radius: 4px;' }"
                        >
                        </dx-button>
                      </div>

                      <!-- Total Price -->
                      <div class="total-price text-right" style="min-width: 56px;">
                        {{ item.totalPrice | number : "1.2-2" }}
                      </div>
                    </div>
                    <div class="border">
                      <ul *ngIf="item?.itemVariations" class="variations" style="display: flex; padding-left: 0; gap: 3px; flex-wrap: wrap;">
                        <ng-container
                          *ngTemplateOutlet="
                            itemVaraitionGridTemplate;
                            context: { list: item.itemVariations }
                          "
                        ></ng-container>
                      </ul>
                      <ul *ngIf="item.itemExtras" class="extras">
                        <li
                          *ngFor="let extra of item.itemExtras"
                          class="text-secondary"
                        >
                          {{ extra.name }}
                          <b>{{ extra.price | number : "1.2-2" }}</b>
                        </li>
                      </ul>
                    </div>
                  </ng-container>
                </div>
              </dx-scroll-view>
            </div>
            <div class="card-footer px-0 pt-0">
              <div class="container-fluid mt-auto">
                <!-- Total Payable Section -->
                <div class="row mb-2">
                  <div class="col-12 px-0">
                    <div class="border p-3 bg-light">
                      <div class="d-flex justify-content-between text-left">
                        <!-- Subtotal -->
                        <div class="mr-4 mb-2">
                          <label class="d-block">Ara Toplam:</label>
                          <b>{{ totalPayable | number : "1.2-2" }}</b>
                        </div>

                        <!-- Total Discount -->
                        <div class="mr-4 mb-2">
                          <label class="d-block">İndirim:</label>
                          <b>{{ 0 | number : "1.2-2" }}</b>
                        </div>

                        <!-- Tax -->
                        <div class="mr-4 mb-2">
                          <label class="d-block">Vergi:</label>
                          <b>{{ 0 | number : "1.2-2" }}</b>
                        </div>

                        <!-- Total Payable -->
                        <div class="mr-4 mb-2">
                          <label class="d-block">Toplam:</label>
                          <b>{{ totalPayable | number : "1.2-2" }}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Buttons Row -->
                <div
                  class="d-flex bg-light"
                  style="justify-content: space-between"
                >
                  <!-- <div class="col text-right px-0">
                    <button
                      class="btn btn-outline-danger mr-2"
                      (click)="onCancelClicked()"
                    >
                      {{ order?.id ? "İptal Et" : "Temizle" }}
                    </button>
                    <button
                      *ngIf="order?.id"
                      class="btn btn-primary mr-2 px-2"
                      (click)="openSplitOrderPopup()"
                    >
                      Böl
                    </button>
                    <button
                      *ngIf="order?.id"
                      class="btn btn-primary btn-outline mr-2"
                      (click)="openPaymentPopup()"
                    >
                      Ödeme Al
                    </button>
                    <button class="btn btn-success" (click)="onSubmitClicked()">
                      Kaydet
                    </button>
                  </div> -->

                  <!-- Clear Button: Icon Button, Rounded -->
                  <dx-button
                    icon="clear"
                    stylingMode="outlined"
                    type="danger"
                    [elementAttr]="{ style: 'border-radius: 0px;' }"
                    class="clear-button mr-2"
                    (onClick)="onCancelClicked()"
                  >
                  </dx-button>

                  <!-- Save Button: Fills the remaining space -->
                  <dx-button
                    [text]="selectedOrderTypeIndex == 1 ? 'ÖDEME AL' : 'KAYDET'"
                    class="save-button"
                    type="default"
                    (onClick)="onSubmitClicked()"
                  >
                  </dx-button>

                  <dx-button
                    *ngIf="order?.id && selectedOrderTypeIndex == 0"
                    text="PARÇALI ÖDEME AL"
                    class="save-button ml-2"
                    type="success"
                    stylingMode="outlined"
                    (onClick)="openSplitOrderPopup()"
                  >
                  </dx-button>

                  <dx-button
                    *ngIf="order?.id"
                    text="ÖDEME AL"
                    class="save-button ml-2"
                    type="success"
                    (onClick)="openPaymentPopup()"
                  >
                  </dx-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  *ngIf="categoryPopupVisible"
  [visible]="categoryPopupVisible"
  [showCloseButton]="true"
  [maxHeight]="'80vh'"
  [minHeight]="'40vh'"
  title="Tüm Kategoriler"
  [width]="'65vw'"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  (onHiding)="categoryPopupVisible = false"
>
  <div class="row mx-0 px-0 justify-content-center">
    <div *ngFor="let item of availableCategories">
      <dx-button
        [text]="item"
        (onClick)="changeCategory(item); categoryPopupVisible = false"
        [stylingMode]="'outlined'"
        type="default"
        class="mr-2 mb-2"
        width="150"
        [height]="60"
      >
      </dx-button>
    </div>
  </div>
</dx-popup>

<dx-popup
  [visible]="variationPopupVisible"
  [showCloseButton]="true"
  [title]="selectedItem?.name + ' Ürün Varyasonları'"
  [width]="'60vw'"
  [height]="'600px'"
  (onHiding)="variationPopupVisible = false"
>
  <dx-scroll-view
    height="500px"
    width="100%"
    showScrollbar="true"
    [scrollByContent]="true"
    [scrollByThumb]="true"
    [useNative]="false"
  >
    <div *ngIf="selectedItem?.itemAttributes?.length">
      <p class="w-100 h5">Varyasonlar</p>
      <hr class="w-100" />

      <div *ngFor="let attribute of selectedItem?.itemAttributes">
        <div class="bg-white py-1">{{ attribute.name }}</div>
        <div class="d-flex">
          <dx-radio-group
            [dataSource]="attribute.mappedVariations"
            valueExpr="id"
            displayExpr="name"
            layout="vertical"
            [(value)]="attribute.selectedVariation"
          ></dx-radio-group>
        </div>
      </div>
      <hr class="w-100" />
    </div>

    <div *ngIf="selectedItem?.extras?.length">
      <p class="w-100 h5">Ekstralar</p>
      <hr class="w-100" />
      <div class="d-flex">
        <div *ngFor="let extra of selectedItem.extras" class="mr-2">
          <dx-check-box valueExpr="id" [(value)]="extra.selected">
          </dx-check-box>
          {{ extra.name }} (+{{ extra.price | number : "1.2-2" }})
        </div>
      </div>
    </div>
    <hr class="w-100" />
  </dx-scroll-view>

  <div class="w-100">
    <dx-button
      text="Onayla"
      type="default"
      [width]="'100%'"
      stylingMode="outlined"
      (onClick)="onVariationConfirm(getSelectedVariations())"
    ></dx-button>
  </div>
</dx-popup>

<dx-popup
  [width]="'60vh'"
  [height]="'100%'"
  [showTitle]="true"
  title="Aktif Siparişler"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  [hideOnOutsideClick]="true"
  container=".dx-viewport"
  [(visible)]="activeOrderPopupVisible"
>
  <dxo-position at="right" my="right" collision="fit"> </dxo-position>
  <div *dxTemplate="let data of 'content'">
    <div class="d-flex align-items-center px-0 w-100">
      <dx-button
        [text]="'POS'"
        class="btn-lg"
        [stylingMode]="
          selectedOrderProviderFilter == 4 ? 'contained' : 'outlined'
        "
        type="default"
        (onClick)="selectedOrderProviderFilter = 4"
      >
      </dx-button>

      <dx-button
        [text]="'ONLINE'"
        [stylingMode]="
          selectedOrderProviderFilter != 4 ? 'contained' : 'outlined'
        "
        type="default"
        (onClick)="selectedOrderProviderFilter = -1"
      >
      </dx-button>
    </div>

    <div class="row">
      <div class="col-12">
        <dx-scroll-view
          [scrollByContent]="true"
          [scrollByThumb]="true"
          [bounceEnabled]="false"
          [useNative]="false"
        >
          <dx-accordion
            class="full_height"
            #accordion
            [dataSource]="
              orderDataSource
                | filterByProp
                  : 'provider'
                  : 4
                  : selectedOrderProviderFilter != 4
            "
            [collapsible]="true"
            [multiple]="false"
            [selectedItems]="[]"
            [animationDuration]="300"
          >
            <div *dxTemplate="let order of 'title'">
              <div class="header">
                <ng-container
                  *ngIf="
                    selectedOrderProviderFilter == 4;
                    else onlineOrderHeader
                  "
                >
                  {{ order.provider == 4 ? order.tableName : "" }}
                </ng-container>

                <ng-template #onlineOrderHeader>
                  {{ order.provider == 4 ? "POS" : "Getir" }} |
                  {{ order.orderSerialNo }}
                </ng-template>
              </div>
            </div>
            <div *dxTemplate="let order of 'item'">
              <div>
                <p>
                  <b>No: </b>
                  <span>{{ order.orderSerialNo }}</span>
                </p>
                <p>
                  <span *ngIf="order.provider == 4">
                    Masa: {{ order.tableName }}</span
                  >
                  <br />
                  <span>Tutar: {{ order.totalPrice }}</span>
                </p>
              </div>
              <div>
                <p>
                  Durum: <b>{{ getStatusName(order.status) }}</b>
                </p>
                <p>
                  Sağlayıcı: <b>{{ order.provider == 4 ? "POS" : "Getir" }} </b>
                </p>
              </div>

              <div class="mt-2">
                <dx-button
                  class="mt-2"
                  [text]="'Seç'"
                  icon="search"
                  [stylingMode]="'outlined'"
                  type="danger"
                  width="100%"
                  (onClick)="getOrderDetailClicked(order.id)"
                >
                </dx-button>
              </div>
            </div>
          </dx-accordion>
        </dx-scroll-view>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="400"
  [height]="'100%'"
  [showTitle]="true"
  title="Sipariş İptal"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  container=".dx-viewport"
  [(visible)]="cancelPopupVisible"
>
  <dxo-position at="right" my="right" collision="fit"> </dxo-position>

  <dxi-toolbar-item
    toolbar="bottom"
    location="before"
    widget="dxButton"
    [options]="{
      stylingMode: 'contained',
      type: 'success',
      text: 'Kaydet',
      onClick: cancelOrder
    }"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="before"
    [options]="{
      stylingMode: 'contained',
      type: 'normal',
      text: 'Vazgeç',
      onClick: onCloseClicked
    }"
  >
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'">
    <div class="row">
      <div class="col-12 mb-2 mb-md-3">
        <div class="label w-100">İptal Etme Nedeni*</div>
        <dx-text-box
          stylingMode="outlined"
          [(value)]="selectedOrderForCancel.note"
          [showClearButton]="true"
        ></dx-text-box>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [visible]="isPaymentPopupVisible"
  [width]="400"
  [height]="'auto'"
  [showTitle]="true"
  title="Ödeme Al"
  (onHiding)="isPaymentPopupVisible = false"
>
  <div class="p-3">
    <!-- Ödeme Yöntemi -->
    <div class="mb-3">
      <dx-select-box
        [items]="paymentMethods"
        [(value)]="selectedPaymentMethod"
        placeholder="Ödeme Yöntemi Seçiniz"
        displayExpr="text"
        valueExpr="key"
      ></dx-select-box>
    </div>

    <!-- Nakit Ödeme -->
    <div *ngIf="selectedPaymentMethod === 0" class="mb-3">
      <dx-number-box
        [(value)]="receivedAmount"
        [min]="totalPayable"
        placeholder="Alınan Ücret"
      ></dx-number-box>
      <div class="mt-2 d-flex justify-content-between">
        <span>Para Üstü:</span>
        <span
          ><b>{{ receivedAmount - totalPayable | number : "1.2-2" }}</b></span
        >
      </div>
    </div>

    <!-- Ödeme Durumu -->
    <div class="d-flex align-items-center mt-3">
      <dx-switch [(value)]="isPaid" onText="Evet" offText="Hayır"></dx-switch>
      <span class="ml-2">Ödendi</span>
    </div>

    <!-- İşlem Butonları -->
    <div class="text-right mt-4">
      <dx-button
        text="İptal"
        type="danger"
        stylingMode="outlined"
        class="mr-2"
        (onClick)="isPaymentPopupVisible = false"
      ></dx-button>
      <dx-button
        text="Kaydet"
        type="success"
        stylingMode="contained"
        (onClick)="sendOrderStatusUpdate()"
      ></dx-button>
    </div>
  </div>
</dx-popup>

<dx-popup
  [(visible)]="splitOrderPopupVisible"
  [width]="800"
  [height]="600"
  [showTitle]="true"
  [hideOnOutsideClick]="false"
  [dragEnabled]="true"
  title="Parçalı Ödeme"
  (onHiding)="closeSplitOrderPopup()"
>
  <dx-scroll-view
    [height]="570"
    [scrollByContent]="true"
    [scrollByThumb]="true"
    [bounceEnabled]="false"
    [useNative]="false"
  >
    <div class="table-container split-order">
      <!-- Active Order Items Table -->
      <div class="table">
        <p>Mevcut Ürünler</p>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Ürün</th>
              <th>Fiyat</th>
              <th>Adet</th>
              <th>Toplam</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of cloneCart">
              <td style="width: 200px">
                <ng-container
                  *ngTemplateOutlet="itemNameTemplate; context: { item: item }"
                ></ng-container>
              </td>
              <td>
                {{
                  item.price + item.itemExtraTotal + item.itemVariationTotal
                    | number : "1.2-2"
                }}
                <br />
                <span class="text-danger">
                  -{{ item.discount | number : "1.2-2" }}
                </span>
              </td>
              <td>{{ item.quantity }}</td>
              <td>
                <div class="d-flex justify-content-between">
                  <span>
                    {{
                      (item.price +
                        item.itemExtraTotal +
                        item.itemVariationTotal) *
                        item.quantity | number : "1.2-2"
                    }}
                  </span>
                  <button
                    class="btn btn-primary btn-sm px-3 ml-2"
                    (click)="moveToSelected(item)"
                  >
                    +
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Selected Items Table -->
      <div class="table">
        <p>Ödenecek Ürünler</p>
        <dx-scroll-view
          [height]="300"
          [scrollByContent]="true"
          [scrollByThumb]="true"
          [bounceEnabled]="false"
          [useNative]="false"
        >
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Ürün</th>
                <th>Fiyat</th>
                <th>Adet</th>
                <th>Toplam</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of selectedSplitCart">
                <td style="width: 200px">
                  <ng-container
                    *ngTemplateOutlet="
                      itemNameTemplate;
                      context: { item: item }
                    "
                  ></ng-container>
                </td>
                <td>
                  {{
                    item.price + item.itemExtraTotal + item.itemVariationTotal
                      | number : "1.2-2"
                  }}
                  <br />
                  <span class="text-danger">
                    -{{ item.discount | number : "1.2-2" }}
                  </span>
                </td>
                <td>
                  {{ item.quantity }}
                </td>
                <td>
                  <div class="d-flex justify-content-between">
                    <span>
                      {{
                        (item.price +
                          item.itemExtraTotal +
                          item.itemVariationTotal) *
                          item.quantity | number : "1.2-2"
                      }}
                    </span>
                    <button
                      class="btn btn-danger btn-sm px-3 ml-2"
                      (click)="moveBackToOrder(item)"
                    >
                      -
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </dx-scroll-view>
        <!-- Payment Summary -->
        <div class="payment-summary">
          <p class="text-left font-weight-bold">
            Toplam: {{ calculateSplitOrderTotal() | number : "1.2-2" }}
          </p>
          <div class="">
            <!-- Ödeme Yöntemi -->
            <div class="mb-3 d-flex">
              <dx-select-box
                [items]="paymentMethods"
                [(value)]="selectedPaymentMethod"
                placeholder="Ödeme Yöntemi Seçiniz"
                displayExpr="text"
                valueExpr="key"
              ></dx-select-box>

              <div *ngIf="selectedPaymentMethod === 0" class="ml-2">
                <dx-number-box
                  [(value)]="receivedAmount"
                  [min]="calculateSplitOrderTotal()"
                  placeholder="Alınan Ücret"
                ></dx-number-box>
              </div>
            </div>

            <!-- Ödeme Durumu -->
            <div class="d-flex align-items-center justify-content-between mt-2">
              <div class="d-flex">
                <dx-switch
                  [(value)]="isPaid"
                  onText="Evet"
                  offText="Hayır"
                ></dx-switch>
                <span class="ml-2">Ödendi</span>
              </div>
              <div
                class="d-flex justify-content-between"
                *ngIf="selectedPaymentMethod === 0"
              >
                <span class="mr-2">Para Üstü:</span>
                <span
                  ><b>{{
                    receivedAmount - calculateSplitOrderTotal()
                      | number : "1.2-2"
                  }}</b></span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Submit and Cancel Buttons -->
        <div class="buttons">
          <button class="btn btn-secondary" (click)="closeSplitOrderPopup()">
            İptal
          </button>
          <button class="btn btn-success" (click)="submitSplitOrder()">
            Kaydet
          </button>
        </div>
      </div>
    </div>
  </dx-scroll-view>
</dx-popup>

<ng-template #itemNameTemplate let-item="item">
  <div class="w-100">
    <p class="text-dark">{{ item.itemName }}</p>
    <ul *ngIf="item.itemVariations">
      <ng-container
        *ngTemplateOutlet="
          itemVaraitionTemplate;
          context: { list: item.itemVariations }
        "
      ></ng-container>
    </ul>

    <ul *ngIf="item.itemExtras">
      <li *ngFor="let variation of item.itemExtras" class="text-secondary">
        {{ variation.name }}
        <b>{{ variation.price | number : "1.2-2" }}</b>
      </li>
    </ul>

    <div *ngIf="item?.instruction" class="alert alert-dark mb-0" role="alert">
      <b>Müşteri Notu:</b>
      <br />
      {{ item.instruction }}
    </div>
  </div>
</ng-template>
<ng-template #itemVaraitionTemplate let-list="list">
  <li *ngFor="let variation of list" class="text-secondary">
    {{ variation.itemAttributeName }}:
    {{ variation.name }}
    <b>{{ variation.price | number : "1.2-2" }}</b>

    <ul *ngIf="variation.basicItemVariations?.length > 0">
      <ng-container
        *ngTemplateOutlet="
          itemVaraitionTemplate;
          context: {
            list: variation.basicItemVariations
          }
        "
      ></ng-container>
    </ul>
  </li>
</ng-template>

<ng-template #itemVaraitionGridTemplate let-list="list">
  <li *ngFor="let variation of list" class="text-secondary" style="flex-grow: 1;">
    <div style="border: 1px solid #ff5722" class="mr-1">
      <div class="px-2 py-1 text-white" style="background: #ff5722;font-size: 9px; text-align: left;">
          {{ variation.itemAttributeName }}
      </div>
      <div class="px-2" style="white-space: nowrap;">
        {{ variation.name }} <b>{{ variation.price | number : "1.2-2" }}</b>
      </div>

      <ul *ngIf="variation.basicItemVariations?.length > 0">
        <ng-container
          *ngTemplateOutlet="
            itemVaraitionGridTemplate;
            context: { list: variation.basicItemVariations }
          "
        ></ng-container>
      </ul>
    </div>
</ng-template>

<!-- Popup with Virtual NumberPad -->
<dx-popup
  [(visible)]="isCountPopupVisible"
  [showCloseButton]="false"
  [showTitle]="false"
  [width]="300"
  [height]="420"
  [dragEnabled]="true"
>
  <div *dxTemplate="let data of 'content'" class="v-pad">
    <!-- Display Area (like a calculator screen) -->
    <dx-text-box
      [(value)]="currentValue"
      [mode]="'text'"
      class="display-area mx-3"
      stylingMode="outlined"
      [inputAttr]="{ inputmode: 'numeric' }"
      (onValueChanged)="onInputChange($event)"
    >
    </dx-text-box>
    <!-- Number Pad Layout -->
    <div class="number-pad">
      <div class="row">
        <dx-button
          *ngFor="let num of ['1', '2', '3']"
          [text]="num"
          (onClick)="appendNumber(num)"
        >
        </dx-button>
      </div>
      <div class="row">
        <dx-button
          *ngFor="let num of ['4', '5', '6']"
          [text]="num"
          (onClick)="appendNumber(num)"
        >
        </dx-button>
      </div>
      <div class="row">
        <dx-button
          *ngFor="let num of ['7', '8', '9']"
          [text]="num"
          (onClick)="appendNumber(num)"
        >
        </dx-button>
      </div>
      <div class="row">
        <dx-button text="0" (onClick)="appendNumber('0')"></dx-button>
        <dx-button
          text="CLR"
          type="danger"
          (onClick)="clearInput()"
        ></dx-button>
        <dx-button
          text="⌫"
          stylingMode="outlined"
          type="danger"
          style="background-color: #fff"
          (onClick)="removeLastDigit()"
        ></dx-button>
      </div>
      <div class="row">
        <dx-button
          text="Ok"
          class="w-100"
          style="margin: 0 32px"
          (onClick)="applyInput()"
        ></dx-button>
      </div>
    </div>
  </div>
</dx-popup>
