import { Component } from "@angular/core";

import { firstValueFrom } from "rxjs";
import OrderStatusUtils from "src/app/shared/models/order-status.enum";
import { ConvertUtcToLocalPipe } from "src/app/shared/pipes/convert-utc-to-local.pipe";
import { HttpService } from "src/app/shared/services/http.service";

@Component({
  selector: "app-end-of-day-report",
  templateUrl: `./end-of-day-report.component.html`,
  styleUrls: ["./end-of-day-report.component.scss"],
})
export class EndOfDayReportComponent {
  selectedDate: any; // Kullanıcının seçtiği tarih
  loading: boolean = false;
  reportData: any;
    lookupPaymentMethod = OrderStatusUtils.PaymentMethodNamesLookup;

    lookupOrderType = OrderStatusUtils.OrderTypeNamesLookup;
  constructor(    private httpService: HttpService
    
  ) {}

  getEndOfDayReport() {
    const date = new Date(this.selectedDate);
    const localIsoDate = date.toISOString().slice(0, 19); // "2025-01-08T00:00:00"
    this.httpService.get(`Reports/end-of-day?date=${localIsoDate}`).subscribe((res) => {
      this.reportData = res;
    });
  }

}
