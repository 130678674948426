<app-page-header
  [titleText]="'Gün Sonu Raporu'"
></app-page-header>

<div class="container">
  <!-- Tarih Seçimi ve Rapor Al Butonu -->
  <div class="d-flex w-100 mb-3">
    <dx-date-box
      [(value)]="selectedDate"
      displayFormat="dd.MM.yyyy"
      placeholder="Rapor Tarihi Seçiniz"
      width="250px"
    ></dx-date-box>
    <dx-button
      text="Rapor Al"
      type="success"
      [disabled]="loading"
      (onClick)="getEndOfDayReport()"
      class="ml-2"
    ></dx-button>
  </div>

  <!-- Rapor Özeti (Stat Cards) -->
  <div class="row mb-3">
    <div class="col-md-3">
      <div class="card px-3 py-2 shadow">
        <div class="label">Toplam Satış</div>
        <div class="value">{{ reportData?.totalSales | number : '1.2-2' }}</div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card px-3 py-2 shadow">
        <div class="label">Toplam Vergi</div>
        <div class="value">{{ reportData?.totalTax | number : '1.2-2'  }}</div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card px-3 py-2 shadow">
        <div class="label">Toplam İndirim</div>
        <div class="value">{{ reportData?.totalDiscount  | number : '1.2-2' }}</div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card px-3 py-2 shadow">
        <div class="label">Toplam Sipariş</div>
        <div class="value">{{ reportData?.totalOrders }}</div>
      </div>
    </div>
  </div>

  <!-- Detaylı İstatistikler -->
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="card px-3 py-2 shadow">
        <dx-data-grid
          [dataSource]="reportData?.paymentMethodStats"
          [showBorders]="true"
        >
          <dxi-column
            dataField="paymentMethod"
            caption="Ödeme Yöntemi"
            [width]="180"
          >
            <dxo-lookup
              [dataSource]="lookupPaymentMethod"
              valueExpr="id"
              displayExpr="name"
            ></dxo-lookup>
          </dxi-column>
          <dxi-column dataField="count" caption="Sipariş Sayısı"></dxi-column>
        </dx-data-grid>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="label ml-3">Sipariş Türü İstatistikleri</div>
      <dx-data-grid
        [dataSource]="reportData?.orderTypeStats"
        [showBorders]="true"
        class="card px-3 py-2 shadow"
      >
        <dxi-column
          dataField="orderType"
          caption="Sipariş Türü"
          [width]="180"
        >
          <dxo-lookup
            [dataSource]="lookupOrderType"
            valueExpr="id"
            displayExpr="name"
          ></dxo-lookup>
        </dxi-column>
        <dxi-column dataField="count" caption="Sipariş Sayısı"></dxi-column>
      </dx-data-grid>
    </div>
  </div>
</div>
